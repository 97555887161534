<template>
    <div>
        <LoadingSpinner v-if="load_trigger" class="col-12"/>
        <div v-if="horse_names" class="row ml-2 mb-2">
            <h3>{{ $t('acte.trigger_for') }} : {{ horse_names }}</h3>
        </div>
        <div class="row" id="triggers" v-if="!load_trigger && triggers_tab.length > 0">
            <div class="trigger p-3 col-xl-6 col-md-12" v-for="trigger in triggers_tab" :key="trigger.triggerentity_id">
                <template v-if="trigger.entity_type === 'acte'" >
                    <div class="row">
                        <div class="col-6">
                            <h4>{{ trigger.acte_label }}</h4>
                        </div>
                        <div class="col-6" v-if="trigger.selected == 0 " >
                            <input  class="custom-control-input" type="radio" :name=trigger.triggerentity_id :id="trigger.triggerentity_id + '_done'" value="done" @click='changeDate(trigger.triggerentity_id, "done")' v-model="trigger.selected">
                            <label class="custom-control-label"  :for="trigger.triggerentity_id + '_done'" >{{ $t('acte.acte_realise') }}</label>
                        </div>

                        <template v-if="trigger.selected != 0">
                            <div class="col-4" v-if="trigger.selected != 'perso' && trigger.selected != 'done'"><font-awesome-icon :icon="['fal', 'calendar-alt']"/> {{ getDateFormated(date_trigger[trigger.triggerentity_id]) }}</div>
                            <e-datepicker v-if="trigger.selected === 'perso'" class="d-block col-4" v-model="date_trigger[trigger.triggerentity_id]" mode="dateTime"></e-datepicker>
                            <div class="col-4" v-if="trigger.selected == 'done'">{{ $t('acte.acte_realise') }}</div>
                            <div class="col-2">
                                <b-button @click='trigger.selected = 0'>
                                    <font-awesome-icon :icon="['fal', 'times']"/>
                                </b-button>
                            </div>
                        </template>
                    </div>
                    <div class="row" v-if="trigger.selected == 0">
                        <input type="radio" class="d-none" :name=trigger.triggerentity_id :id="trigger.triggerentity_id + '_0'" value="0" v-model="trigger.selected">
                        <div v-for="periode in trigger.period" :key="periode.value" class="col custom-control custom-checkbox">
                            <div v-if="trigger.selected == 0">
                            <input type="radio" class="custom-control-input" :name=trigger.triggerentity_id :id="`${trigger.triggerentity_id}_${periode.value}`" @click='changeDate(trigger.triggerentity_id, periode.value)' v-model="trigger.selected" :value="periode.value">
                            <label class="custom-control-label" :for="`${trigger.triggerentity_id}_${periode.value}`">
                                <strong>{{ periode.label }}</strong>
                                {{ getDatePeriod(trigger.triggerentity_id, periode.value) }}
                            </label>
                            </div>
                        </div>

                        <div v-if="trigger.selected == 0" class="col custom-control custom-checkbox">
                            <input type="radio" class="custom-control-input" :name=trigger.triggerentity_id :id="trigger.triggerentity_id + '_perso'" value="perso" @click='changeDate(trigger.triggerentity_id, "perso")' v-model="trigger.selected">
                            <label class="custom-control-label" :for="trigger.triggerentity_id + '_perso'"><strong>{{ $t('acte.trigger.date_perso') }}</strong>&nbsp;</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" v-if="trigger.selected != 'done' && trigger.selected != 0">
                            <label class="col-form-label">{{ $t('formulaire.ajouter_commentaire') }}</label>
                            <textarea class="form-control" :placeholder="$t('formulaire.votre_commentaire')" v-model="acte_commentaire[trigger.triggerentity_id]"></textarea>
                        </div>
                    </div>

     
                </template>
                <template v-else-if="trigger.entity_type === 'add_categorie_perso'">
                    <h3>{{ $t('trigger.entity_type.'+trigger.entity_type) }}</h3>
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" :name=trigger.triggerentity_id :id="trigger.triggerentity_id" v-model="trigger.selected">
                        <label class="custom-control-label" :for="trigger.triggerentity_id"><strong>{{ trigger.categorie_libelle }}</strong></label>
                    </div>
                </template>
                <template v-else>
                    <h3>{{ $t('trigger.entity_type.'+trigger.entity_type) }}</h3>
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" :name=trigger.triggerentity_id :id="trigger.triggerentity_id" v-model="trigger.selected">
                        <label class="custom-control-label" :for="trigger.triggerentity_id"><strong>{{ $t('acte.trigger.declencher.'+trigger.entity_type) }}</strong></label>
                    </div>
                </template>
            </div>
            <div class="text-center mt-3 col-12">
                <button @click="ignoreTrigger" class="btn btn-secondary rounded-pill mr-4">
                    <font-awesome-icon :icon="['fal', 'times']" /> {{ $t('acte.trigger.ignorer') }}
                </button>
                <button @click="prepareTriggered" class="btn btn-primary rounded-pill">
                    <font-awesome-icon :icon="['far', 'save']" /> {{ $t('global.valider') }}
                </button>
            </div>
        </div>
        <div v-if="!load_trigger && triggers_tab.length === 0">
            {{ $t('acte.trigger.empty') }}
        </div>
    </div>
</template>

<script type="text/javascript">
    import Common from '@/assets/js/common.js'
    import Accounting from "@/mixins/Accounting.js"
    import Actes from "@/mixins/Actes.js"
	import Pension from "@/mixins/Pension.js"
    import Shutter from "@/mixins/Shutter.js"
    import ShutterTriggers from '@/mixins/shutters-manager/Triggers.js'
    import Triggers from "@/mixins/Triggers.js"
    import Horse from "@/mixins/Horse.js"
	import PlanningMonte from "@/mixins/PlanningMonte.js"
    import _deepClone from 'lodash/cloneDeep'

    export default {
        name: "ActeProposition",
		mixins: [Accounting, Actes, Pension, Shutter, ShutterTriggers, Triggers, Horse, PlanningMonte],
        props: ['horses_ids', 'date_acte', 'acte_selected', 'question', 'type_mvt', 'mare_id', 'actes_ids', 'date_retour', 'from'],
        data () {
            return { 
                selected_horses_ids: [],
                load_trigger: false,
                triggers_tab: [],
                trigger_object: {},
                date_trigger: {},
                acte_commentaire: {},
                accounting_plan: null,
                horse_names: '',
                horse_sexes: []
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.selected_horses_ids = this.horses_ids
                await this.formatteHorsesNames()
                this.loadTrigger()
            },
            
            async loadTrigger() {
                this.load_trigger = true;
				if(this.acte_selected) {
                    this.loadTriggerByActe()
                }

                if(this.type_mvt) {
                    this.loadTriggerByMouvement()
                }

                if(this.mare_id) {
                    this.loadTriggerByPoulain()
                }
            },

            async loadTriggerByActe() {
                let questions = {}
                this.question.forEach(q => {
                    if(q.val !== null)
                        questions[q.key] = Array.isArray(q.val) ? q.val : [q.val]
                })
                await this.getTriggerByActe(this.acte_selected.actestype_id, questions).then(res => this.formatTrigger(res))
            },

            async loadTriggerByMouvement() {
                const mare = this.horse_sexes.includes('F')
                const season = await this.getCurrentSeason(new Date())
                await this.getTriggerByMouvement(this.type_mvt, this.date_retour, mare, season.length > 0, this.from).then(res => this.formatTrigger(res))
            },

            async loadTriggerByPoulain() {
                await this.getTriggerByPoulain().then(res => this.formatTrigger(res))
            },

            async formatTrigger(trigger) {
                this.triggers_tab = []
                this.trigger_object = {}
                if(!trigger) {
                    this.load_trigger = false
                    return false
                }
                await Common.asyncForEach(trigger, async (trigger) => {
					
					// Ne pas proposer l'ajout d'embryons si pas la repro
					if(trigger.entity_type === 'add_embryon' && !this.$store.state.userAccess.hasBreedingAccess) {
						return
					}

					// Ne pas proposer le trigger des résultat si mvp
					if(trigger.entity_type === 'resultat_analyse' && !this.$store.state.userAccess.hasConfTableAccess) {
						return
					}

                    let period_res = []
                    let period_tab = {}
                    await Common.asyncForEach(trigger.period, async (period, index) => {
                        let begin_at = period.triggeredperiod_begin_at
                        if(period.triggeredperiod_actestype) {
                            const actes = await this.getActeByTypeAndHorse(period.triggeredperiod_actestype, this.selected_horses_ids[0])
                            if(actes.length > 0) {
                                begin_at += this.calculDifference(this.date_acte, actes[0].actes_date)
                            }
                        }
                        let period_tab_label = period.triggeredperiod_label
                        if(this.$i18n.locale() === 'en') {
                            period_tab_label = period.triggeredperiod_label_en
                        }
                        period_tab[period.triggeredperiod_id] = {
                            label: period_tab_label,
                            begin_at: begin_at,
                            billable: period.triggeredperiod_billable,
                            duration: period.triggeredperiod_duration,
                            recurrence: period.triggeredperiod_recurrence
                        }
                        period_res.push({
                            label: period_tab_label,
                            value: period.triggeredperiod_id
                        })
                    })
                    this.triggers_tab.push({
                        triggerentity_id: trigger.triggerentity_id,
                        acte_id: trigger.actestype_id,
                        acte_label: trigger.actestype_label,
                        categorie_id: trigger.categorie_id,
                        categorie_libelle: trigger.categorie_libelle,
                        entity_type: trigger.entity_type,
                        period: period_res,
                        selected: 0
                    })
                    this.trigger_object[trigger.triggerentity_id] = {
                        acte_id: trigger.actestype_id,
                        acte_label: trigger.actestype_label,
                        categorie_id: trigger.categorie_id,
                        categorie_libelle: trigger.categorie_libelle,
                        entity_type: trigger.entity_type,
                        period: period_tab
                    }
                })
                if(this.triggers_tab.length == 0) {
                    this.cancel()
                }
                this.load_trigger = false;

                if(this.type_mvt == "sortie") {
                    this.triggers_tab.forEach(trigger => {
                        if(trigger.entity_type == 'suppression_acte' || trigger.entity_type == 'pension_cloture') {
                            trigger.selected = 1
                        }
                    })
                }
            },

            getDatePeriod(trigger_id, period) {
                let period_tab = this.trigger_object[trigger_id].period[period];
                let date = new Date(this.date_acte);
                date.setDate(this.date_acte.getDate() + period_tab.begin_at);
                return this.getTrad("trigger.jours."+date.getDay()) + " " + date.getDate() + "/" + (date.getMonth() + 1)
            },
            getDateFormated(date) {
                return this.getTrad("trigger.jours."+date.getDay()) + " " + date.getDate() + "/" + (date.getMonth() + 1)
            },
            changeDate(trigger_id, period) {
                // si la période choisie est la même que celle déjà sélectionnée alors je mets à "ignorer"
                const trigger = this.triggers_tab.find(trigger => trigger.triggerentity_id == trigger_id)
                if(trigger.selected == period) {
                    const index = this.triggers_tab.indexOf(trigger);
                    this.triggers_tab[index].selected = 0
                    return false
                }

                let date = this.date_acte
                if(period != "done" && period != "perso"){
                    let period_tab = this.trigger_object[trigger_id].period[period];
                    date = new Date(this.date_acte);
                    date.setDate(this.date_acte.getDate() + period_tab.begin_at);
                    this.acte_commentaire[trigger_id] = period_tab.label
                }

                const time = new Date()
                date.setHours(time.getHours())
                date.setMinutes(time.getMinutes())

                this.date_trigger[trigger_id] = date
            },

            async valid_trigger() {
                this.load_trigger = true;
                let triggered_acte = []
                let res = []
                for(let i in this.triggers_tab){
                    const triggerentity_id = this.triggers_tab[i].triggerentity_id
                    if(this.trigger_object[triggerentity_id].entity_type == "pension_cloture" && this.triggers_tab[i].selected) {
                        this.triggerCloturePension()
                        continue;
                    }
                    if(this.trigger_object[triggerentity_id].entity_type == "add_categorie_perso" && this.triggers_tab[i].selected) {
                        this.triggerCategoriePerso(this.triggers_tab[i])
                        continue;
                    }
                    if(this.trigger_object[triggerentity_id].entity_type !== 'acte' || !this.trigger_object[triggerentity_id].acte_id) {
                        continue;
                    }
                    let period = this.triggers_tab[i].selected
                    if(period !== 0){
                        let article_triggered = false
                        if(this.hasFacturationAccess) {
                            let articles = await this.loadAccountingPlanActesTypeArticles(this.trigger_object[triggerentity_id].acte_id) 
                            article_triggered = articles.filter(article => article.is_triggered)[0]
                        }

                        let article_triggered_formated = null
                        if(article_triggered){
							article_triggered_formated = {
								accountingplan_id: article_triggered.accountingplan_id,
								id: article_triggered.articles_id,
								label: article_triggered.articles_label,
								prefixe: article_triggered.articles_code,
								ht: article_triggered.articles_ht,
								qte: 1
							}
                        }

                        let billable = 1
                        let period_obj = {duration: 0, recurrence: 0, begin_at: 0}
                        let done = true
                        let trigger_label = ""
                        if(period !== "done" && period !== "perso") {
                            done = false
                            billable = this.trigger_object[triggerentity_id].period[period].billable // 0 => Pas facturer, 1 => Facturer tout, 2 => Facturer un seul
                            period_obj = this.trigger_object[triggerentity_id].period[period]
                            trigger_label = this.trigger_object[triggerentity_id].period[period].label
                        }
                        if(period === "perso") {
                            done = false
                        }

                        if(period_obj.duration != 0 && period_obj.recurrence != 0){
                            let start = this.calculDifference(this.date_acte, this.date_trigger[triggerentity_id]);
                            for(let j = start; j < period_obj.duration + start; j+= period_obj.recurrence){
                                let new_date = new Date(this.date_acte);

                                new_date.setDate(this.date_acte.getDate() + j);
                
                                const time = new Date()
                                new_date.setHours(time.getHours())
                                new_date.setMinutes(time.getMinutes())

                                if(billable === 1 || (billable === 2 && j === period_obj.begin_at)){
                                    res = await this.createActe(this.trigger_object[triggerentity_id].acte_id, new_date, done, triggerentity_id, trigger_label, this.acte_commentaire[triggerentity_id], article_triggered_formated)
                                }else {
                                    res = await this.createActe(this.trigger_object[triggerentity_id].acte_id, new_date, done, triggerentity_id, trigger_label, this.acte_commentaire[triggerentity_id])
                                }
                            }
                        }else {
                            let new_date = new Date(this.date_trigger[triggerentity_id]);
                            // if(done) {
                            //     new_date = new Date(this.date_acte)
                            // }
                            // const time = new Date()
                            // new_date.setHours(time.getHours())
                            // new_date.setMinutes(time.getMinutes())

                            if(billable === 1 || billable === 2){
                                res = await this.createActe(this.trigger_object[triggerentity_id].acte_id, new_date, done, triggerentity_id, trigger_label, this.acte_commentaire[triggerentity_id], article_triggered_formated)
                            }else {
                                res = await this.createActe(this.trigger_object[triggerentity_id].acte_id, new_date, done, triggerentity_id, trigger_label, this.acte_commentaire[triggerentity_id])
                            }
                            if(done) {
                                triggered_acte = triggered_acte.concat(res)
                            }
                        }
                    }
                }

                return triggered_acte;
            },

            async createActe(acte_id, date, done, trigger_id, trigger_label, commentaire, article = null) {
                let statut = done ? 1 : 0;
                let articles = []
                if(article != null) {
                    let article_local = _deepClone(article)
                    article_local.ht = (article.ht / 100).toString()
                    articles.push(article_local)
                }

                // 13/05/22 Bertrand : on laisse vide si pas de commentaire
                // si commentaire vide je prends le label du déclencheur 
                // const com = commentaire == '' ? trigger_label : commentaire 

                let result = await this.addActes(this.selected_horses_ids, statut, date, commentaire, acte_id, "", this.accounting_plan, articles, [], trigger_id)
                return result
            },

            // calcul le nombre de jours entre la date de l'acte et celle du déclenché pour la boucle de création
            calculDifference(date1, date2) {
                date1.setHours(0,0,0,0);
                date2.setHours(0,0,0,0);
                let Difference_In_Time = date2.getTime() - date1.getTime(); 
                let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
                return Math.round(Difference_In_Days);
            },

            async triggerCloturePension() {
                for(let i in this.selected_horses_ids) {
                    await this.cloturePension(this.selected_horses_ids[i], this.date_acte)
                }
            },

            async triggerCategoriePerso(trigger) {
                const cat_id = trigger.categorie_id
                for(let i in this.selected_horses_ids) {
                    await this.addHorseCategorie(this.selected_horses_ids[i], cat_id)
                }
            },

            async prepareTriggered() {
                const triggered_acte = await this.valid_trigger()

                let res = []
                for(let i in triggered_acte) {
                    res.push({
                        type: 'acte',
                        horses: this.horses_ids,
                        date: this.date_acte,
                        id: triggered_acte[i]
                    })
                }

                for(let i in this.triggers_tab) {
                    const triggerentity_id = this.triggers_tab[i].triggerentity_id
                    if(this.triggers_tab[i].selected && ((!['acte', 'pension_cloture', 'add_categorie_perso'].includes(this.trigger_object[triggerentity_id].entity_type) && this.triggers_tab[i].selected) || (this.trigger_object[triggerentity_id].entity_type == "acte" && !this.trigger_object[triggerentity_id].acte_id))) {
                        res.push({
                            type: this.trigger_object[triggerentity_id].entity_type,
                            horses: this.horses_ids,
                            date: this.date_trigger[triggerentity_id] ?? this.date_acte,
                            acte_type: this.acte_selected,
                            actes_ids: this.actes_ids,
                            id: null,
                        })
                        
                        // si l'acte déclencheur est un Sevrage et que le déclenché est un changement de catégorie ou une pension 
                        // je déclenche également sur la mère si elle existe
                        if(this.acte_selected && this.acte_selected.actestype_code == "SVRG" && ['change_categorie', 'pension'].includes(this.trigger_object[triggerentity_id].entity_type)) {
                            for(let i in this.horses_ids) {
                                const mare = await this.getFoalMare(this.horses_ids[i])
                                if(mare) {
                                    res.push({
                                        type: this.trigger_object[triggerentity_id].entity_type,
                                        horses: [mare.horse_id],
                                        date: this.date_trigger[triggerentity_id],
                                        acte_type: this.acte_selected,
                                        actes_ids: this.actes_ids,
                                        id: null,
                                    })
                                }
                            }
                        }
                    }
                }

                if(res.length == 0) {
                    this.ignoreTrigger()
                    return false
                }

                this.ok(res)
            },

            ignoreTrigger() {
                this.cancel()
            },

            async formatteHorsesNames() {
                const horses = await this.getHorsesByIds(this.horses_ids)
                this.horse_sexes = horses.map(horse => horse.horse_sexe)
                this.horse_names = horses.map(horse => horse.horse_nom).join(', ')
            }
        },
        watch: {
            'horses_ids' (val) {
                this.selected_horses_ids = val
            },
        },
        computed: {
            hasFacturationAccess() {
                return this.$store.state.userAccess.hasFacturationAccess
            },
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
        }
    }
</script>